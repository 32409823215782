<template>
  <div>
    <div class="w-full">
      <div class="">
        <div class="steps-content">
          <a-button
            :style="{
              border: 'none',
              background: 'none',
              color: '#000',
              boxShadow: 'none',
              padding: '0px'
            }"
            class="my-6"
            @click="$router.go(-1)"
          >
            <template #icon>
              <ArrowLeftOutlined />
            </template>
            Login</a-button
          >
          <div>
            <transition name="route">
              <RegisterStepOne
                v-show="current === 0"
                @goNext="handleNextStep"
              />
            </transition>
            <transition name="route">
              <RegisterStepTwo
                v-show="current === 1"
                @goNext="handleNextStep"
                @goPrev="handlePrevStep"
              />
            </transition>
            <transition name="route">
              <RegisterStepThree
                v-show="current === 2"
                @goNext="handleNextStep"
                @goPrev="handlePrevStep"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterStepOne from './components/RegisterStepOne.vue'
import RegisterStepTwo from './components/RegisterStepTwo.vue'
import RegisterStepThree from './components/RegisterStepThree.vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs, ref } from 'vue'

export default {
  components: {
    ArrowLeftOutlined,
    RegisterStepOne,
    RegisterStepTwo,
    RegisterStepThree
  },
  setup() {
    const state = reactive({})
    const current = ref(0)
    const handleNextStep = (step) => {
      current.value = step
    }
    const handlePrevStep = (step) => {
      current.value = step
    }
    return { ...toRefs(state), handlePrevStep, handleNextStep, current }
  }
}
</script>

<style lang="scss" scoped></style>
