<template>
  <div>
    <h2 class="font-bold text-4xl">Tell us a little bit about yourself.</h2>
    <p class="auth__headings">
      This is an initial information about you the owner of this brand, this
      will help us to keep in touch with you and always be by your side as your
      brand grows.
    </p>
    <div class="mt-8">
      <a-form
        layout="vertical"
        :model="stepOneForm"
        :rules="rules"
        ref="stepOne"
      >
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="First Name:" name="firstname">
              <a-input
                v-model:value="stepOneForm.firstname"
                placeholder="John"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Last Name:" name="lastname">
              <a-input
                v-model:value="stepOneForm.lastname"
                placeholder="Ademola"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Email:" name="email">
          <a-input
            v-model:value="stepOneForm.email"
            placeholder="ademola@gmail.com"
          />
        </a-form-item>
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="24">
            <a-form-item label="Phone Number:" name="phone">
              <a-input
                v-model:value="stepOneForm.phone"
                placeholder="9013747651"
                type="number"
              >
                <template #addonBefore>
                  <a-select
                    v-model:value="stepOneForm.code"
                    class="auth__country-code"
                    name="code"
                  >
                    <a-select-option
                      v-for="country in refinedCodes"
                      class="mt-6"
                      :key="country.abbr"
                      :value="country.phoneCode"
                      >{{
                        `${country.abbr} (+${country.phoneCode})`
                      }}</a-select-option
                    >
                  </a-select>
                </template>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Password:" name="password">
              <a-input-password
                v-model:value="stepOneForm.password"
                placeholder="Enter Password"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Confirm Password:" name="cpassword">
              <a-input-password
                v-model:value="stepOneForm.cpassword"
                placeholder="Re-type Password"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-form-item>
            <AppButton buttonText="Next" @buttonAction="handleNext">
              <template v-slot:icon>
                <RightOutlined class="text-sm" />
              </template>
            </AppButton>
          </a-form-item>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import RefinedCodes from '@/helpers/countryCodes'
import { RightOutlined } from '@ant-design/icons-vue'
export default {
  components: { RightOutlined },
  setup(props, context) {
    const store = useStore()
    const refinedCodes = RefinedCodes()
    const localState = reactive({
      stepOneForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        cpassword: '',
        code: ''
      }
    })
    const stepOne = ref()
    // validation rules
    let validateConfirmPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again')
      } else if (value !== localState.stepOneForm.password) {
        return Promise.reject("Two inputs don't match!")
      } else {
        return Promise.resolve()
      }
    }
    const rules = {
      firstname: [
        {
          required: true,
          message: 'Please enter your firstname',
          trigger: 'blur'
        }
      ],
      lastname: [
        {
          required: true,
          message: 'Please enter your lastname',
          trigger: 'blur'
        }
      ],
      email: [
        {
          required: true,
          message: 'please enter your email',
          trigger: 'blur'
        },
        {
          type: 'email',
          message: 'Please enter a valid email',
          trigger: 'blur'
        }
      ],
      phone: [
        {
          required: true,
          message: 'Please enter a phone number',
          trigger: 'blur'
        },
        {
          min: '10',
          max: '15',
          message: 'please input a valid phone number',
          trigger: 'change'
        }
      ],
      code: [
        {
          required: true,
          message: 'Please select a country code',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: 'Please add a password.',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message:
            'Passwords must have atleast 6 characters and maximum of 12 characters.',
          trigger: 'change'
        }
      ],
      cpassword: [{ validator: validateConfirmPassword, trigger: 'change' }]
    }
    const onSelect = ({ dialCode }) => {
      localState.code = `+${dialCode}`
    }
    const handleNext = () => {
      stepOne.value
        .validate()
        .then(() => {
          if (localState.stepOneForm.code) {
            store.dispatch('auth/UpdateRegisterForm', localState.stepOneForm)
            context.emit('goNext', 1)
          } else {
            let notification = {
              status: 'error',
              message: 'please add a country code',
              title: 'Form Error'
            }
            store.dispatch('notify/add', notification)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return {
      ...toRefs(localState),
      rules,
      stepOne,
      onSelect,
      refinedCodes,
      handleNext
    }
  }
}
</script>

<style lang="scss" scoped></style>
