<template>
  <div>
    <div class="mt-20 text-center">
      <CheckCircleTwoTone twoToneColor="#52c41a" class="text-5xl" />
      <h3>Registration process completed</h3>
      <p class="text-md my-4">
        Welcome to Aura Magazine's Lookbook platform, please check your email
        for further instructions.
      </p>
      <AppButton buttonText="Login" @buttonAction="$router.push('/')" />
    </div>
  </div>
</template>

<script>
import { CheckCircleTwoTone } from '@ant-design/icons-vue'
export default {
  components: { CheckCircleTwoTone },
  setup() {}
}
</script>

<style lang="scss" scoped></style>
