<template>
  <div class="overflow-y-scroll pb-12" :style="{ maxHeight: '850px' }">
    <h2 class="font-bold text-4xl">Tell us a little bit about your brand.</h2>
    <p class="w-8-12">
      This is an initial information about your brand or business, this helps us
      know how to serve you better thanks.
    </p>
    <div class="mt-8">
      <a-form
        layout="vertical"
        :model="stepTwoForm"
        :rules="rules"
        ref="stepOne"
      >
        <a-form-item label="Brand Name:" name="brandName">
          <a-input
            v-model:value="stepTwoForm.brandName"
            placeholder="Daper Luk"
          />
        </a-form-item>
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="FaceBook Link:" name="facebookUrl">
              <a-input v-model:value="stepTwoForm.facebookUrl" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Instagram Link:" name="instagramUrl">
              <a-input v-model:value="stepTwoForm.instagramUrl" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="24">
            <a-form-item label="Twitter Link:" name="twitterUrl">
              <a-input v-model:value="stepTwoForm.twitterUrl"> </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="mt-12" :gutter="24">
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Website Link:" name="websiteUrl">
              <a-input v-model:value="stepTwoForm.websiteUrl" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="24" :lg="12">
            <a-form-item label="Website Type:" name="websiteType">
              <a-select ref="select" v-model:value="stepTwoForm.websiteType">
                <a-select-option value="website"
                  >Brand Awareness Website</a-select-option
                >
                <a-select-option value="e-commerce"
                  >E-commerce Website</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="Description" name="description">
              <a-textarea
                v-model:value="stepTwoForm.description"
                placeholder="Tell users about your brand..."
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-form-item>
            <a-button @click="handlePrev">
              <template v-slot:icon>
                <LeftOutlined class="text-sm" />
              </template>
              Previous
            </a-button>
          </a-form-item>
          <a-form-item>
            <AppButton
              buttonText="Finish"
              @buttonAction="handleNext"
              :loading="requestLoading"
            />
          </a-form-item>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import RefinedCodes from '@/helpers/countryCodes'
import { LeftOutlined } from '@ant-design/icons-vue'
import useLoadingState from '@/use/loading-state'

export default {
  components: { LeftOutlined },
  setup(props, context) {
    const { requestLoading } = useLoadingState()
    const store = useStore()
    const refinedCodes = RefinedCodes()
    const localState = reactive({
      stepTwoForm: {
        brandName: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        websiteType: '',
        websiteUrl: '',
        description: ''
      }
    })
    const stepOne = ref()
    // validation rules
    const rules = {
      brandName: [
        {
          required: true,
          message: 'Please enter your brand name',
          trigger: 'blur'
        }
      ],
      description: [
        {
          required: true,
          message: 'Tell us more about your brand.',
          trigger: 'blur'
        }
      ],
      facebookUrl: [
        {
          type: 'url',
          message: 'Please enter a valid url.',
          trigger: 'change'
        }
      ],
      instagramUrl: [
        {
          type: 'url',
          message: 'Please enter a valid url.',
          trigger: 'change'
        }
      ],
      twitterUrl: [
        {
          type: 'url',
          message: 'Please enter a valid url.',
          trigger: 'change'
        }
      ],
      websiteUrl: [
        {
          type: 'url',
          message: 'Please enter a valid url.',
          trigger: 'change'
        }
      ]
    }
    const handleNext = async () => {
      try {
        await stepOne.value.validate()
        store.dispatch('auth/UpdateRegisterForm', localState.stepTwoForm)
        const response = await store.dispatch('auth/RegisterDesigner')
        if (response) {
          context.emit('goNext', 2)
        } else {
          const notification = {
            status: 'error',
            message: 'Error creating your account, please try again.',
            title: 'Account Error'
          }
          store.dispatch('notify/add', notification)
        }
      } catch (error) {
        console.log('validation error', error)
      }
    }
    const handlePrev = () => {
      context.emit('goPrev', 0)
    }
    return {
      ...toRefs(localState),
      rules,
      stepOne,
      refinedCodes,
      handleNext,
      handlePrev,
      requestLoading
    }
  }
}
</script>

<style lang="scss" scoped></style>
