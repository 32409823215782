/*
 * Extracted loading state from vuex store into this composition function
 * This code is reusable to get loading state from vuex store when making request
 * once the loading state is set in your store this function can be imported into any component to get the loading state
 * more loaders can be added if needed here
 * Written by Dropcode
 */

import { useStore } from 'vuex'
import { computed } from 'vue'

export default function useLoadingState() {
  const store = useStore()
  const requestLoading = computed(() => {
    return store.state.loader.requestLoader
  })
  const formLoading = computed(() => {
    return store.state.loader.formLoader
  })

  return { requestLoading, formLoading }
}
